var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-form',{ref:"form",staticClass:"pt-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"px-6 pt-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":['Email', 'Pushnotification'],"hide-selected":"","item-text":"label","item-value":"id","placeholder":"Tipo","label":"Tipo","outlined":"","dense":"","clearable":true,"required":"","rules":_vm.rules.selectRules,"disabled":_vm.viewOnly},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),_c('v-text-field',{attrs:{"placeholder":"Assunto","label":"Assunto","outlined":"","dense":"","required":"","rules":_vm.rules.maxCharacters,"disabled":_vm.viewOnly,"hint":"Não pode ser superior a 255 caracteres.","counter":255},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),(_vm.formData.type === 'Email')?_c('v-editor',{attrs:{"label":"Mensagem","value":_vm.formData.message,"required":_vm.requiredMessage,"disabled":_vm.viewOnly},on:{"update":function($event){_vm.formData.message = $event}}}):_c('v-textarea',{attrs:{"placeholder":"Mensagem","label":"Mensagem","outlined":"","dense":"","required":"","rules":_vm.rules.genericRules,"disabled":_vm.viewOnly,"auto-grow":""},model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}}),_c('div',[_c('p',{staticClass:"caption mb-1"},[_vm._v("Destinatários")]),_c('v-form',{ref:"formRecipients",staticClass:"d-flex justify-space-between",attrs:{"lazy-validation":""},model:{value:(_vm.validLinks),callback:function ($$v) {_vm.validLinks=$$v},expression:"validLinks"}},[_c('v-autocomplete',{staticClass:"mr-4",attrs:{"items":[
                'Administradores',
                'Representantes',
                'Gerentes distritais(GD)',
                'Força de venda',
                'Funcionário',
              ],"hide-selected":"","item-text":"label","item-value":"id","placeholder":"Tipo","label":"Tipo","outlined":"","dense":"","clearable":true,"required":"","rules":_vm.rules.selectRules,"disabled":_vm.viewOnly},model:{value:(_vm.typeRecipients),callback:function ($$v) {_vm.typeRecipients=$$v},expression:"typeRecipients"}}),(
                _vm.typeRecipients === 'Força de venda' ||
                _vm.typeRecipients === 'Funcionário'
              )?_c('v-autocomplete',{staticClass:"mr-4",attrs:{"items":_vm.typeRecipients === 'Funcionário'
                  ? _vm.listFuncionarios
                  : _vm.listForcaDeVendas,"hide-selected":"","item-text":"label","item-value":"id","placeholder":"Tipo id","label":"Tipo id","return-object":"","outlined":"","dense":"","clearable":true,"required":"","rules":_vm.rules.selectRules,"disabled":_vm.viewOnly},model:{value:(_vm.typeIdRecipients),callback:function ($$v) {_vm.typeIdRecipients=$$v},expression:"typeIdRecipients"}}):_vm._e(),_c('v-btn',{attrs:{"depressed":"","fab":"","dark":"","small":"","color":"fqm_gray","disabled":_vm.viewOnly},on:{"click":_vm.adicionar}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),(_vm.formData.recipients)?_c('v-simple-table',{staticClass:"mb-6",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"grey lighten-4"},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Tipo")]),_c('th',{staticClass:"text-left"},[_vm._v("Id")]),(!_vm.viewOnly)?_c('th',{staticClass:"text-left"},[_vm._v("Remover")]):_vm._e()])]),_c('tbody',_vm._l((_vm.formData.recipients),function(item,index){return _c('tr',{key:item.index},[_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(item.typeName))]),(!_vm.viewOnly)?_c('td',[_c('v-btn',{attrs:{"depressed":"","fab":"","dark":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.remover(index)}}},[_c('v-icon',[_vm._v(" mdi-minus ")])],1)],1):_vm._e()])}),0)]},proxy:true}],null,false,608136246)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-6 pb-4"},[(!_vm.viewOnly)?_c('v-btn',{attrs:{"depressed":"","color":"fmq_gray","dark":"","loading":_vm.loadingBtn},on:{"click":_vm.send}},[_vm._v(_vm._s(_vm.isEdit ? "Editar" : "Criar"))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }