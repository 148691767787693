import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listSaleForce = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.SALE_FORCE_LIST);
    return data
}

export const searchSaleForce = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.SALE_FORCE_SEARCH, payload);
    return data
}

export const showSaleForce = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.SALE_FORCE_SHOW + `/${id}` );
    return data
}

export const updateProductsSaleForce = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.SALE_FORCE_UPDATE_PRODUCTS + `/${id}`, payload );
    return data
}