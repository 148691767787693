<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="6">
          <v-autocomplete
            v-model="formData.type"
            :items="['Email', 'Pushnotification']"
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Tipo"
            label="Tipo"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
            :disabled="viewOnly"
          ></v-autocomplete>

          <v-text-field
            v-model="formData.subject"
            placeholder="Assunto"
            label="Assunto"
            outlined
            dense
            required
            :rules="rules.maxCharacters"
            :disabled="viewOnly"
            hint="Não pode ser superior a 255 caracteres."
            :counter="255"
          ></v-text-field>

          <v-editor
            v-if="formData.type === 'Email'"
            @update="formData.message = $event"
            label="Mensagem"
            :value="formData.message"
            :required="requiredMessage"
            :disabled="viewOnly"
          >
          </v-editor>

          <v-textarea
            v-else
            v-model="formData.message"
            placeholder="Mensagem"
            label="Mensagem"
            outlined
            dense
            required
            :rules="rules.genericRules"
            :disabled="viewOnly"
            auto-grow
          ></v-textarea>

          <div>
            <p class="caption mb-1">Destinatários</p>
            <v-form
              ref="formRecipients"
              v-model="validLinks"
              lazy-validation
              class="d-flex justify-space-between"
            >
              <v-autocomplete
                v-model="typeRecipients"
                :items="[
                  'Administradores',
                  'Representantes',
                  'Gerentes distritais(GD)',
                  'Força de venda',
                  'Funcionário',
                ]"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Tipo"
                label="Tipo"
                outlined
                dense
                :clearable="true"
                required
                :rules="rules.selectRules"
                :disabled="viewOnly"
                class="mr-4"
              ></v-autocomplete>

              <v-autocomplete
                v-if="
                  typeRecipients === 'Força de venda' ||
                  typeRecipients === 'Funcionário'
                "
                v-model="typeIdRecipients"
                :items="
                  typeRecipients === 'Funcionário'
                    ? listFuncionarios
                    : listForcaDeVendas
                "
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Tipo id"
                label="Tipo id"
                return-object
                outlined
                dense
                :clearable="true"
                required
                :rules="rules.selectRules"
                :disabled="viewOnly"
                class="mr-4"
              ></v-autocomplete>

              <v-btn
                depressed
                fab
                dark
                small
                color="fqm_gray"
                @click="adicionar"
                :disabled="viewOnly"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-form>

            <v-simple-table class="mb-6" v-if="formData.recipients">
              <template v-slot:default>
                <thead class="grey lighten-4">
                  <tr>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Id</th>
                    <th v-if="!viewOnly" class="text-left">Remover</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in formData.recipients"
                    :key="item.index"
                  >
                    <td>{{ item.type }}</td>
                    <td>{{ item.typeName }}</td>
                    <td v-if="!viewOnly">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        @click="remover(index)"
                      >
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        v-if="!viewOnly"
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar" : "Criar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "NotificacaoFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    listForcaDeVendas: {
      type: Array,
      default: () => [],
    },
    listFuncionarios: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      type: null,
      subject: null,
      message: null,
      recipients: [],
    },
    typeRecipients: null,
    typeIdRecipients: null,
    rules: rules,
    valid: true,
    isEdit: false,
    viewOnly: false,
    validLinks: false,
    requiredMessage: false,
  }),
  created() {
    if (
      this.$route.name === "NotificacaoVisualizar" ||
      this.$route.name === "NotificacaoEditar"
    ) {
      this.formData.type = this.item.type;
      this.formData.subject = this.item.subject;
      this.formData.message = this.item.message;

      this.item.recipients.forEach((item) => {
        this.formData.recipients.push({
          type: item.type,
          typeId: item.typeId,
          typeName: item.recipientInfo.name,
        });
      });
      this.isEdit = true;
      if (this.$route.name === "NotificacaoVisualizar") this.viewOnly = true;
    } else if (this.$store.state.duplicateNotification.length && this.$store.state.duplicateNotification[0]) {
      const duplicate = { ...this.$store.state.duplicateNotification[0] };
      this.formData.type = duplicate.type;
      this.formData.subject = duplicate.subject;
      this.formData.message = duplicate.message;

      duplicate.recipients.forEach((item) => {
        this.formData.recipients.push({
          type: item.type,
          typeId: item.typeId,
          typeName: item.recipientInfo.name,
        });
      });
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateRecipients() {
      this.$refs.formRecipients.validate();
    },
    send() {
      if (this.formValid && this.formData.message) {
        this.$emit("send", this.formData);
        this.$store.dispatch("setDuplicateNotification", null);
      } else {
        this.requiredMessage = true;
      }
    },
    adicionar() {
      if (this.formRecipientsValid) {
        if (
          this.typeRecipients === "Força de venda" ||
          this.typeRecipients === "Funcionário"
        ) {
          if (
            !this.formData.recipients.some(
              (item) => item.typeName === this.typeIdRecipients.label
            )
          ) {
            this.formData.recipients.push({
              type: this.typeRecipients,
              typeId: this.typeIdRecipients.id,
              typeName: this.typeIdRecipients.label,
            });
          }
        } else {
          if (
            !this.formData.recipients.some(
              (item) => item.type === this.typeRecipients
            )
          ) {
            this.formData.recipients.push({
              type: this.typeRecipients,
            });
          }
        }

        this.$refs.formRecipients.reset();
        (this.typeRecipients = null), (this.typeIdRecipients = null);
      }
    },
    remover(index) {
      if (index > -1 && index < this.formData.recipients.length) {
        this.formData.recipients.splice(index, 1);
      }
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
    formRecipientsValid() {
      return this.$refs.formRecipients.validate();
    },
  },
};
</script>
<style lang="css" scoped>
::v-deep.theme--light.v-input--is-disabled input,
::v-deep.theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 1);
  opacity: 1;
}
</style>
