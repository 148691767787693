import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const searchEmployee = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.EMPLOYEE_SEARCH, payload);
    return data
}

export const showEmployee = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EMPLOYEE_SHOW + `/${id}`);
    return data
}

export const editEmployee = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EMPLOYEE_EDIT + `/${id}`, payload);
    return data
}

export const importEmployee = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.EMPLOYEE_IMPORT, payload);
    return data
}

export const listEmployee = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.EMPLOYEE_LIST);
    return data
}