import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listProductNotification = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.NOTIFICATION_LIST, payload);
    return data
}

export const searchNotification = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.NOTIFICATION_SEARCH, payload);
    return data
}

export const storeNotification = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.NOTIFICATION_STORE, payload);
    return data
}

export const showNotification = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.NOTIFICATION_SHOW + `/${id}`);
    return data
}

export const editNotification = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.NOTIFICATION_EDIT + `/${id}`, payload);
    return data
}

export const deleteNotification = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.NOTIFICATION_DELETE + `/${id}`);
    return data
}

export const sendNotification = async (id) => {
    const { data } = await apiInstance.post(ENDPOINTS.NOTIFICATION_SEND + `/${id}`);
    return data
}